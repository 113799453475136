import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Chart(props) {

  
  return (
    <div>
      <Navbar />
      <ScrollToTopOnMount />

      
  
      <section className="banner chart">
      <div className="container">
      <div className="box_flx">
      <div className="g_box">
      <img src={require("../assets/images/g_chart.png")} alt="Icon" className="img-fluid" />
      </div>
      <div className="right_box">
        <div className="d-flex align-items-center mb-4">
        <i class="fas fa-list me-2"></i>
            <p className="mb-0" >Quick View</p>
        </div>
        <div className="box mb-2">
        <div className="head">
        <div className="d-flex align-items-center">
        <i class="fas fa-play-circle me-2"></i>     
        Live Round 
        </div>
        <h4>9256</h4>
        </div>
        <div class="progress">
                 <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
        <ul>
            <li>
                <span>Prize Pool</span>
                <p>10.4334BNB</p>
            </li>
            <li>
                <span>Payouts</span>
                <p>2.00X/2.00X</p>
            </li>
            <li>
                <span>Locked Price</span>
                <p>$21666.29</p>
            </li>
            <li>
                <span>Result</span>
                <p className="butn" >
                <img src={require("../assets/images/right_arw.png")} alt="Icon" className="img-fluid" />
                    DOWN</p>
            </li>   
        </ul>
        </div>
        <div className="box">
        <div className="head">
        <div className="d-flex align-items-center">
        <i class="far fa-clock me-2"></i>  
        Next Round 
        </div>
        <h4>9256</h4>
        </div>
        <ul>
            <li>
                <span>Prize Pool</span>
                <p>10.4334BNB</p>
            </li>
            <li>
                <span>Payouts</span>
                <p>2.01X/1.19X</p>
            </li>
        
        </ul>
        
        </div>
        <button className="butn butn1">Connect Wallet</button>
      </div>
      </div>
       </div> 
      
      <div className="bannerbg">
       </div>
      </section>
   





      <Footer />
    </div>
  );
}
