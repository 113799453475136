import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import kukuapi from "../ABI/KUKU.json";
import wethabi from "../ABI/BEP721.json";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import "@metamask/legacy-web3";
import config from "../lib/config/config";
import { toast } from "react-toastify";
import { toastAlert } from "../helper/toastAlert";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  MenuOpen,
  Notifications,
  AccountBalanceWallet,
  MeetingRoom,
} from "@material-ui/icons";
import { getprovider } from "../pages/seperate/provider";
import LotterytokenABI from "../ABI/Lotterytoken.json";

//
import {
  useEthersSigner,
  walletClientToSigner,
} from "../pages/ethersConnect.js";

import {
  // type PublicClient,
  // type WalletClient,
  useWalletClient,
  usePublicClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import { connection } from "../helper/connection";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";

let toasterOption = config.toasterOption;

export default function Navbar(props) {
  const [selected, setSelected] = useState("GB");

  const [account, setaccount] = useState();

  const { classes, handleDrawerOpen, open, handleMenu, handleClose, anchorEl } =
    props;

  const [currAddr, setcurrAddr] = React.useState("");
  const [blns, setBln] = React.useState(0);
  const [tokenbalance, settokenbalance] = React.useState(0);
  const [wethbalance, setwethbalance] = React.useState(0);

  const walletConnection = useSelector((state) => state.walletConnection);
  console.log(walletConnection, "walletConnection");

  const dispatch = useDispatch();
  const [metamaskenable, setmetamaskenable] = useState(true);

  const network = useSwitchNetwork({
    onMutate(args) {
      console.log("Mutate", args);
    },
    onError(error) {
      console.log("Error", error);
    },
  });

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    },
  });

  const { disconnect } = useDisconnect();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const { chain, chains } = useNetwork();

  let chainId = config.NetworkId;
  console.log(chainId, "chainIdcccccc", chain);
  const { data: walletClient } = useWalletClient({ chainId });

  useEffect(() => {
    setConnection();
  }, [walletClient, chain?.network]);

  async function setConnection() {
    console.log("RRRRRRRRRRRRRRRRRRRR", walletClient);

    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);
      console.log("transportsssssssssss: ", transport);
      var web3 = new Web3(transport);

      console.log("userdetails: ", {
        network: config.NetworkId,
        web3: web3,
        address: walletClient.account.address,
        provider: transport,
        connect: "yes",
        isChange: "false",
        // userdetails: userdetails
      });
      console.log(
        config.NetworkId,
        chain.id,
        "config.NetworkIdconfig.NetworkId"
      );
      //var web3New = new Web3(window.ethereum);
      //var netId = await web3New.eth.net.getId();
      //console.log(netId, 'netIdnetIdnetId')
      if (chain.id == config.NetworkId) {
        dispatch(
          setWallet({
            network: config.NetworkId,
            web3: web3,
            address: walletClient.account.address,
            provider: transport,
            connect: "yes",
            isChange: "false",
          })
        );
      }

      console.log(signer, transport, "@@@@@@@@@@@@@@@@@@@1");
    } else {
      var web3 = new Web3("https://bsc-dataseed1.binance.org");
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
    }
  }

  async function connectfunction(connector) {
    try {
      let check = isMobile();

      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toast.error("Please connect smart chain", toasterOption);
          return;
        }
      }

      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        //window.location.reload(false);
      }, 1000);
    } catch (err) {}
  }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  async function disconnectMetamask() {
    localStorage.clear();

    disconnect();

    setTimeout(function () {
      window.location.reload(false);
    }, 1000);
  }

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    // $(document).ready(function () {
    //   $(".main_navbar ul.navbar-nav li a").click(function () {
    //     $(".navbar-toggler").trigger("click");
    //   });
    // });
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };
  useEffect(() => {
    getconnect();
  }, [walletConnection, walletClient]);

  async function getconnect() {
    var { provider, address } = await connection();
    console.log(provider, "provider");
    if (provider) {
      var web3 = new Web3(provider);
      try {
        if (typeof provider !== "undefined") {
          setcurrAddr(address);
          var balance = await web3.eth.getBalance(address);
          var currbal = balance / 1000000000000000000;
          setBln(currbal.toFixed(5));
          var CoursetroContract = new web3.eth.Contract(
            LotterytokenABI,
            config.lotteryToken
          );
          var tokenDethBln = await CoursetroContract.methods
            .balanceOf(address)
            .call();
          var value = tokenDethBln / 1000000000000000000;
          settokenbalance(value.toFixed(2));
          var CoursetroContract1 = new web3.eth.Contract(
            LotterytokenABI,
            config.lotteryToken
          );
          var tokenDethBln1 = await CoursetroContract1.methods
            .balanceOf(address)
            .call();
          var value1 = tokenDethBln1 / 1000000000000000000;
          setwethbalance(value1.toFixed(2));
          console.log(currbal, value1, "valuefgyfg");
        } else {
          //  toast.success("Please Add Metamask External", toasterOption);
        }
      } catch (err) {}
    } else {
      // toast.success("Please Add Metamask External", toasterOption);
    }
  }

  return (
    <>
      <nav className="navbar navbar-light navbar-expand-sm bg-light main_navbar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid"
              alt="Icon"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            {walletConnection && walletConnection.address == "" ? (
              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar_right">
                  <li class="nav-item">
                    <button
                      className="btn butn my-0"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      <img
                        src={require("../assets/images/wallet.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                      CONNECT WALLET
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="ms-auto">
                <IconButton
                  aria-owns={open ? "menu-appbar" : undefined}
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  className="profileButton butn"
                >
                  <AccountBalanceWallet />
                  <Typography className="wallet_address">
                    {halfAddrShow(walletConnection.address)}
                  </Typography>
                </IconButton>
                <Menu
                  style={{ marginTop: "50px", marginLeft: "50px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="menudrop"
                >
                  <MenuItem>
                    <div className="dropProfilePic">
                      <Typography className="dropProfileName mb-3">
                        <span>Balance in BNB</span>: {blns}
                      </Typography>
                      <Typography className="dropProfileName">
                        <span>Balance in {config.tokenSymbol}</span>:{" "}
                        {tokenbalance}
                      </Typography>
                    </div>
                  </MenuItem>

                  <MenuItem onClick={() => disconnectMetamask()}>
                    <MeetingRoom /> Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector) => (
                      <>
                        {connector && connector.id == "metaMask" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/metamask.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>MetaMask</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                        {connector && connector.id == "walletConnect" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/trustwallet.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>Trust Wallet</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
