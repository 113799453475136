import React, { useEffect,useState } from "react";
import { storeEmail } from "../actions/User";
import config from "../lib/config/config";
import { toast } from "react-toastify";
let toasterOption = config.toasterOption;

const initialFormValue = {
  email: "",
};


export default function Footer(props) {
  const [formValue, setFormValue] = React.useState(initialFormValue);
  const {email} = formValue;

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue,"formValue");
  };

  const handleclick = async (e) => {
    var req = {
      email: email
    }
    const result = await storeEmail(req);
    console.log(result, "result**");
    if(result?.result?.status){
      toast.success("Succeffully Saved", toasterOption);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
            <h2>Don't miss out, Be the first to know</h2>
            <p>A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange that uses cryptography</p>
            {/* <div class="input-group">
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                aria-label="Email Address"
                aria-describedby="email"
                id="email"
                value={email}
                onChange={onChange}
              />
              <button type="button" class="input-group-text primary_btn" onClick={handleclick}><span className="">SEND NOW</span></button>
            </div>          */}
             
            <ul className="footer_social_links">
              
              {/* <li>
                <a href="/" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
             
              <li>
                <a href="/" target="_blank">
                <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                <i class="fab fa-youtube"></i>
                </a>
              </li> */}

              <li>
                <a href="/" target="_blank">
                <i class="fab fa-telegram"></i>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="footer_panel_bottom">
            <ul className="footer_list_links">
            <li><a href="#">Terms & Conditions </a></li>   
              <li><a href="#"> Risk Disclaimer </a></li>   
              <li><a href="#">Privacy Policy</a></li>           
            </ul>
            {/* <p>Copyright © {new Date().getFullYear()} <span>Bonjour</span>, All rights Reserved</p> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
